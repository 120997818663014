// Copyright 2024, Imprivata, Inc.  All rights reserved.

// TODO: replace string literals with AuhnMethod enum values
// when it can be imported from the authn module without
// 'react/jsx-runtime' error
export const availableFactorTypes = ['pwd', 'impr-id', 'impr-pin'];

export enum QueryParam {
  AcrValues = 'acrValues',
  ContextType = 'contextType',
  ResourceType = 'resourceType',
  SpRequestId = 'spRequestId',
  TenantId = 'tenantId',
  Username = 'username',
  LoginHint = 'loginHint',
}
