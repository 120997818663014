// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { CodingContext } from '@imprivata-cloud/data-privacy-js';
import getConfig from '../appConfigUtils';
import {
  IdpAuthnContinueWithAuthzCodeRequest,
  IdpAuthnContinueWithAuthzCodeResponse,
  IdpAuthnContinueWithIdTokenRequest,
  IdpAuthnContinueWithIdTokenResponse,
  IdpAuthnStartRequest,
  IdpAuthnStartResponse,
  ImprHeaders,
} from '../shared/types';
import { base64toBase64Url } from '../utils';
import { client, retryOn401Client } from './client';

const { API_URL } = getConfig();

export const startIdpAuthn = async (
  // despite the fact that availableFactorTypes is optional for this endpoint in some cases,
  // it makes more sense to always send it
  payload: IdpAuthnStartRequest & { availableFactorTypes: string[] },
): Promise<IdpAuthnStartResponse> => {
  const { data } = await retryOn401Client.post<IdpAuthnStartResponse>(
    `${API_URL}/idp-web-authn/v1/idp-authn/start`,
    payload,
  );

  return data;
};

export const continueWithIdtoken = async (
  idToken: string | undefined,
  payload: IdpAuthnContinueWithIdTokenRequest,
): Promise<IdpAuthnContinueWithIdTokenResponse> => {
  const headers: Record<string, string> = {};

  if (idToken) {
    const codingContext = CodingContext.createContextV1();

    const encryptedIdToken = codingContext.encryptString(idToken);

    headers[ImprHeaders.ImprCodingCtx] =
      codingContext.buildImprCodingCtxHeader();
    headers[ImprHeaders.ImprAuthorization] =
      'Bearer ' + base64toBase64Url(encryptedIdToken.data);
  }

  const { data } =
    await retryOn401Client.post<IdpAuthnContinueWithIdTokenResponse>(
      `${API_URL}/idp-web-authn/v1/idp-authn/continue/with-id-token`,
      payload,
      { headers },
    );

  return data;
};

export const continueWithAuthzCode = async (
  payload: IdpAuthnContinueWithAuthzCodeRequest,
): Promise<IdpAuthnContinueWithAuthzCodeResponse> => {
  const { data } =
    await retryOn401Client.post<IdpAuthnContinueWithAuthzCodeResponse>(
      `${API_URL}/idp-web-authn/v1/idp-authn/continue/with-authz-code`,
      payload,
    );

  return data;
};

export function setDefaultHeader(headerName: string, value: string): void {
  client.defaults.headers.common[headerName] = value;
  retryOn401Client.defaults.headers.common[headerName] = value;
}

export function deleteHeader(headerName: string): void {
  delete client.defaults.headers.common[headerName];
  delete retryOn401Client.defaults.headers.common[headerName];
}
